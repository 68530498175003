export const STUDIO_STEPS = {
  connect: 'connect',
  create: 'create',
  destinations: 'destinations',
  model: 'model',
  review: 'review',
  schedule: 'schedule',
  selectData: 'selectData',
  test: 'test',
};

export const STUDIO_NESTED_VIEWS = {
  customConnection: 'customConnection',
  existingConnection: 'existingConnection',
  modelDataset: 'modelDataset',
  modelFrame: 'modelFrame',
  newConnection: 'newConnection',
  reviewDataset: 'reviewDataset',
  reviewDatasetFrame: 'reviewDatasetFrame',
  scheduleDataset: 'scheduleDataset',
  testDataset: 'testDataset',
};

export const STUDIO_ONBOARDING_STEP_STATUSES = {
  completed: 'completed',
  failed: 'failed',
  pending: 'pending',
  started: 'started',
};

export const STUDIO_ROUTES = {
  [STUDIO_NESTED_VIEWS.modelDataset]: 'studio-data-products-dataProductId-model-datasets-datasetId',
  [STUDIO_NESTED_VIEWS.modelFrame]: 'studio-data-products-dataProductId-model-datasets-datasetId-frames-frameId',
  [STUDIO_NESTED_VIEWS.scheduleDataset]: 'studio-data-products-dataProductId-schedule-datasets-datasetId',
  [STUDIO_NESTED_VIEWS.testDataset]: 'studio-data-products-dataProductId-test-datasets-datasetId',
  [STUDIO_STEPS.create]: 'studio-data-products-create',
  [STUDIO_STEPS.connect]: 'studio-data-products-dataProductId-connect',
  [STUDIO_NESTED_VIEWS.customConnection]: 'studio-data-products-dataProductId-connect-custom',
  [STUDIO_NESTED_VIEWS.existingConnection]: 'studio-data-products-dataProductId-connect-existing',
  [STUDIO_NESTED_VIEWS.newConnection]: 'studio-data-products-dataProductId-connect-new',
  [STUDIO_STEPS.destinations]: 'studio-data-products-dataProductId-destinations',
  [STUDIO_STEPS.model]: 'studio-data-products-dataProductId-model',
  [STUDIO_STEPS.review]: 'studio-data-products-dataProductId-review',
  [STUDIO_NESTED_VIEWS.reviewDataset]: 'studio-data-products-dataProductId-review-datasets-datasetId',
  [STUDIO_NESTED_VIEWS.reviewDatasetFrame]:
    'studio-data-products-dataProductId-review-datasets-datasetId-frames-frameId',
  [STUDIO_STEPS.schedule]: 'studio-data-products-dataProductId-schedule',
  [STUDIO_STEPS.selectData]: 'studio-data-products-dataProductId-select-data',
  [STUDIO_STEPS.test]: 'studio-data-products-dataProductId-test',
  dataProduct: 'studio-data-products-dataProductId',
  studio: 'studio',
};

export const ONBOARDING_SCHEDULE_COMPLETENESS_ATTRIBUTE = 'onboardingScheduleCompleted';
export const ONBOARDING_REVIEW_COMPLETENESS_ATTRIBUTE = 'onboardingReviewCompleted';
